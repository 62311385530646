import api from '../library/axios';
import config from '../config';

export default {
  getList({
    workspace_id,
    page,
    search,
    search_by,
    label_id,
    channel,
  }) {
    return api({
      method: 'get',
      url: `/api/contacts/${workspace_id}`,
      params: {
        page,
        search,
        search_by,
        label_id,
        channel,
      },
    });
  },
  getListBlockContact({
    workspace_id,
    page,
    search,
    search_by,
    label_id,
    channel,
  }) {
    return api({
      method: 'get',
      url: `/api/contacts/${workspace_id}/block/list`,
      params: {
        page,
        search,
        search_by,
        label_id,
        channel,
      },
    });
  },
  getListAll({
    workspace_id,
    page,
    search,
    search_by,
  }) {
    return api({
      method: 'get',
      url: `/api/all-contacts/${workspace_id}`,
      params: {
        page,
        search,
        search_by,
      },
    });
  },
  detail({
    workspace_id,
    id,
  }) {
    return api({
      method: 'get',
      url: `/api/contacts/${workspace_id}/${id}/detail`,
    });
  },
  update({
    workspace_id,
    contact_id,
    data,
  }) {
    return api({
      method: 'patch',
      url: `/api/contacts/${workspace_id}/${contact_id}`,
      data,
    });
  },
  mergeContact({
    workspace_id,
    target_id,
    parent_id,
  }) {
    return api({
      method: 'post',
      baseURL: config.api.chatUrl,
      url: `/api/contacts/merge/${workspace_id}`,
      data: {
        target_id,
        parent_id,
      },
    });
  },
  blockContact({
    contact_id,
    blocked_reason,
  }) {
    return api({
      method: 'post',
      url: '/api/contacts/block',
      data: {
        contact_id,
        blocked_reason,
      },
    });
  },
  unblockContact({
    contact_id,
  }) {
    return api({
      method: 'post',
      url: '/api/contacts/unblock',
      data: {
        contact_id,
      },
    });
  },
  deleteContact({
    workspace_id,
    contact_id,
  }) {
    return api({
      method: 'delete',
      url: `/api/contacts/${workspace_id}/${contact_id}`,
    });
  },
  importContact(workspace_id, data) {
    return api({
      method: 'post',
      url: `/api/contacts/${workspace_id}/bulk`,
      data,
    });
  },
  get_field({
    workspace_id,
  }) {
    return api({
      method: 'get',
      url: `/api/contact-settings/${workspace_id}`,
    });
  },
  update_contact_field(workspace_id, data) {
    return api({
      method: 'patch',
      url: `/api/contact-settings/${workspace_id}`,
      data,
    });
  },
  check_field({
    workspace_id,
    key,
  }) {
    return api({
      method: 'get',
      url: `/api/contact-settings/${workspace_id}/check_field`,
      params: {
        key,
      },
    });
  },
};
